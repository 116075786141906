import React from 'react';
import _ from 'lodash';

import {getData} from '../utils';
import PortfolioPostFeedItem from './PortfolioPostFeedItem';

export default class PortfolioFeedItemFilter extends React.Component {
    render() {
        let section = _.get(this.props, 'portfolio_feed_section', null);
        let section_author = _.get(this.props, 'section_author', null);
        let section_category = _.get(this.props, 'section_category', null);
        let section_tag = _.get(this.props, 'section_tag', null);
        let portfolio = _.get(this.props, 'portfolio_page', null);
        return (
            section_author ? (
            	_.get(portfolio, 'frontmatter.author', null) && ((() => {
            	    let portfolio_author = getData(this.props.pageContext.site.data, _.get(portfolio, 'frontmatter.author', null));
            	    return (
                		(portfolio_author.id === _.get(section_author, 'id', null)) && (
                			<PortfolioPostFeedItem {...this.props} portfolio_feed_section={section} portfolio_page={portfolio} />
                		)
                	);
            	})())
            ) : (section_category ? (
            	_.map(_.get(portfolio, 'frontmatter.categories', null), (category, category_idx) => {
            	    let portfolio_category = getData(this.props.pageContext.site.data, category);
            	    return (
                		(portfolio_category.id === _.get(section_category, 'id', null)) && (
                			<PortfolioPostFeedItem key={category_idx} {...this.props} portfolio_feed_section={section} portfolio_page={portfolio} />
                		)
                	)
            	})
            ) : (section_tag ? (
            	_.map(_.get(portfolio, 'frontmatter.tags', null), (tag, tag_idx) => {
            	    let portfolio_tag = getData(this.props.pageContext.site.data, tag);
            	    return (
                		(portfolio_tag.id === _.get(section_tag, 'id', null)) && (
                			<PortfolioPostFeedItem key={tag_idx} {...this.props} portfolio_feed_section={section} portfolio_page={portfolio} />
                		)
                	)
            	})
            ) : 
            	<PortfolioPostFeedItem {...this.props} portfolio_feed_section={section} portfolio_page={portfolio} />
            ))
        );
    }
}
